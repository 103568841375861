<template>
  <v-layout column>
    <v-row align="center" justify="center">
      <v-col xs="9" sm="9" md="6" lg="6" xl="6" class="align-center">
        <div class="align-center text-center mt-2">
          <v-card elevation="6" max-width="600" style="margin: auto">
            <v-toolbar flat dense class="blue darken-3" dark>
              <v-toolbar-title v-if="this.$vuetify.breakpoint.width > 900">
                <span style="font-size: 20px">{{
                  $t("titles.rssiInfo")
                }}</span></v-toolbar-title
              >
              <v-toolbar-title v-if="this.$vuetify.breakpoint.width < 900">
                <span style="font-size: 20px">{{
                  $t("titles.rssiInfo")
                }}</span></v-toolbar-title
              >
            </v-toolbar>

            <div v-if="$i18n.locale == 'en'" class="pl-4 pr-4 pt-2 pb-4">
              This is a formula to help you calculate your desired rssi value.
              <br />
              Note that this formula does not take into consideration any
              interferences to your signal such as walls.
              <br />
              <br />
              <p>meters = 10 ^ ((-69 -(RSSI_VALUE))/(10 * 2))</p>
              <br />
              Please note that this calculated distance is approximated.
              <br />
              <v-btn
                width="150"
                class="mt-2"
                elevation="2"
                @click="navigateTo({ name: 'deviceSettings' })"
              >
                {{ $t("button.backToDeviceSettings") }}
              </v-btn>
            </div>
            <div v-if="$i18n.locale == 'pt'" class="pl-4 pr-4 pt-2 pb-4">
              A seguinte formula ajuda-o a calcular o valor desejável para o
              rssi.
              <br />
              Note que esta formula não tem em consideração quaisquer
              interferências ao sinal, como por exemplo paredes.
              <br />
              <br />
              <p>metros = 10 ^ ((-69 -(VALOR_RSSI))/(10 * 2))</p>
              <br />
              Note que a distância calculada é aproximada.
              <br />
              <v-btn
                width="250"
                class="mt-2"
                elevation="2"
                @click="navigateTo({ name: 'deviceSettings' })"
              >
                {{ $t("button.backToDeviceSettings") }}
              </v-btn>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
export default {
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
  },
};
</script>
